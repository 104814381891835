import React from 'react';
import UserManagement from './UserManagement';

const UserManagementTab = () => {
  return (
    <div>
      <UserManagement />
    </div>
  );
};

export default UserManagementTab;