import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import UserProfile from './components/UserProfile';
import ForgotPassword from './components/ForgotPassword';
import ResetPasswordConfirm from './components/ResetPasswordConfirm';
import './App.css';
import GoogleCallback from './components/GoogleCallback';
import SignUp from './components/SignUp';
import AccountActivation from './components/AccountActivation';
import UkonLayout from './components/UkonLayout';
import Dashboard from './components/Dashboard';
import Projects from './components/Projects';
import Analytics from './components/Analytics';
import Settings from './components/Settings';
import AcceptInvitation from './components/AcceptInvitation';

import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:uidb64/:token" element={<ResetPasswordConfirm />} />
          <Route path="/google-callback" element={<GoogleCallback />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/users/activate/:uidb64/:token" element={<AccountActivation />} />
          <Route path="/in" element={<UkonLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="profile" element={<UserProfile />} />
            <Route path="projects" element={<Projects />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="settings" element={<Settings />} />
          </Route>
          <Route path="/accept-invitation/:token" element={<AcceptInvitation />} />
          <Route path="*" element={<Navigate to="/in" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
