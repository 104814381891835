import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const GoogleCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokens = params.get('tokens');
    const error = params.get('error');

    if (tokens) {
      const parsedTokens = JSON.parse(decodeURIComponent(tokens));
      localStorage.setItem('accessToken', parsedTokens.access);
      localStorage.setItem('refreshToken', parsedTokens.refresh);
      navigate('/in/dashboard');
    } else if (error) {
      navigate(`/login?error=${encodeURIComponent(error)}`);
    }
  }, [location, navigate]);

  return null;
};

export default GoogleCallback;