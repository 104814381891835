import React from 'react';
import LocationsManagement from './LocationsManagement';

const LocationsTab = () => {
  return (
    <div className="tab-content-container">
      <LocationsManagement />
    </div>
  );
};

export default LocationsTab;