import axios from 'axios';
import i18n from './i18n';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const language = i18n.language;
  config.headers['Accept-Language'] = language;
  
  // Add language to querystring
  const separator = config.url.includes('?') ? '&' : '?';
  config.url += `${separator}language=${language}`;
  
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/token/refresh/`, { refresh: refreshToken });
        const { access } = response.data;
        localStorage.setItem('accessToken', access);
        api.defaults.headers.common['Authorization'] = `Bearer ${access}`;
        originalRequest.headers['Authorization'] = `Bearer ${access}`;
        return api(originalRequest);
      } catch (refreshError) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        const errorMessage = encodeURIComponent(i18n.t('sessionExpired'));
        window.location.href = `/login?error=${errorMessage}`;
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;