import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../api';
import './SignUp.css';

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    registrationType: '',
    subtype: '',
    companyName: '',
    additionalInfo: ''
  });
  const [passwordStrength, setPasswordStrength] = useState('');
  const [emailAvailability, setEmailAvailability] = useState({ message: '', isAvailable: null });
  const [emailCheckTimeout, setEmailCheckTimeout] = useState(null);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber') {
      const cellPhoneRegex = /^(\+|00)?[1-9][0-9]{7,14}$/;
      const isValid = cellPhoneRegex.test(value);
      setIsPhoneValid(isValid);
      setFormData({ ...formData, [name]: value });
    } else if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);
      setFormData({ ...formData, [name]: value });
      if (isValidEmail) {
        if (emailCheckTimeout) clearTimeout(emailCheckTimeout);
        setEmailCheckTimeout(setTimeout(() => checkEmailAvailability(value), 1000));
      } else {
        setEmailAvailability({ message: t('invalidEmail'), isAvailable: false });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
    if (name === 'password') {
      checkPasswordStrength(value);
      validatePasswords(value, formData.confirmPassword);
    } else if (name === 'confirmPassword') {
      validatePasswords(formData.password, value);
    }
  };

  const checkPasswordStrength = (password) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    if (strongRegex.test(password)) {
      setPasswordStrength('strong');
    } else if (mediumRegex.test(password)) {
      setPasswordStrength('medium');
    } else {
      setPasswordStrength('weak');
    }
  };

  const validatePasswords = (password, confirmPassword) => {
    const match = password === confirmPassword;
    setPasswordsMatch(match);
  };

  const checkEmailAvailability = async (email) => {
    if (email) {
      setEmailAvailability({ message: t('checkingEmail'), isAvailable: null });
      try {
        const response = await api.post(`${process.env.REACT_APP_API_URL}/stakeholders/check-email/`, { email });
        if (response.data.available) {
          setEmailAvailability({ message: t('emailAvailable'), isAvailable: true });
        } else {
          setEmailAvailability({ message: t('emailNotAvailable'), isAvailable: false });
        }
      } catch (error) {
        setEmailAvailability({ message: t('emailCheckError'), isAvailable: null });
      }
    } else {
      setEmailAvailability({ message: '', isAvailable: null });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (step === 1) {
      setStep(2);
    } else {
      setIsLoading(true);
      try {
        const registrationData = {
          email: formData.email,
          password: formData.password,
          first_name: formData.firstName,
          last_name: formData.lastName,
          phone_number: formData.phoneNumber,
          registration_type: formData.registrationType,
          subtype: formData.subtype,
          company_name: formData.registrationType === 'retail' 
            ? `${formData.firstName} ${formData.lastName}`
            : formData.companyName,
          description: formData.additionalInfo
        };
        await api.post(`${process.env.REACT_APP_API_URL}/stakeholders/register/`, registrationData);
        setRegistrationStatus('success');
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const errorMessage = Object.values(error.response.data).flat().join(' ');
          setRegistrationStatus(t('registrationError', { error: errorMessage }));
        } else {
          setRegistrationStatus(t('registrationError', { error: error.response?.data?.message || error.message }));
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderStep1 = () => (
    <>
      <div className="input-group">
        <label htmlFor="email">{t('emailLabel')}</label>
        <div className="input-with-check">
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder={t('enterEmail')}
            required
          />
          {emailAvailability.isAvailable && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) && <span className="check">✓</span>}
        </div>
        {emailAvailability.message && (
          <p className={`email-availability ${emailAvailability.isAvailable ? 'available' : 'not-available'}`}>
            <span className="dot"></span>
            {emailAvailability.message}
          </p>
        )}
      </div>
      <div className="input-group">
        <label htmlFor="password">{t('passwordLabel')}</label>
        <div className="input-with-check">
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder={t('enterPassword')}
            required
          />
          {passwordStrength === 'passwordStrong' && <span className="check">✓</span>}
        </div>
        {passwordStrength && (
          <p className={`password-strength ${passwordStrength}`}>
            {t(`password${passwordStrength.charAt(0).toUpperCase() + passwordStrength.slice(1)}`)}
          </p>
        )}
      </div>
      <div className="input-group">
        <label htmlFor="confirmPassword">{t('confirmPasswordLabel')}</label>
        <div className="input-with-check">
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            placeholder={t('confirmPassword')}
            required
          />
          {passwordsMatch && formData.confirmPassword && <span className="check">✓</span>}
        </div>
        {formData.confirmPassword && (
          <p className={`password-match ${passwordsMatch ? 'match' : 'no-match'}`}>
            {passwordsMatch ? t('passwordsMatch') : t('passwordsDontMatch')}
          </p>
        )}
      </div>
      <div className="input-group">
        <label htmlFor="firstName">{t('firstNameLabel')}</label>
        <div className="input-with-check">
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder={t('enterFirstName')}
            required
          />
          {formData.firstName && <span className="check">✓</span>}
        </div>
      </div>
      <div className="input-group">
        <label htmlFor="lastName">{t('lastNameLabel')}</label>
        <div className="input-with-check">
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder={t('enterLastName')}
            required
          />
          {formData.lastName && <span className="check">✓</span>}
        </div>
      </div>
      <div className="input-group">
        <label htmlFor="phoneNumber">{t('phoneNumberLabel')}</label>
        <div className="input-with-check">
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder={t('enterCellPhoneNumber')}
            title={t('cellPhoneValidationMessage')}
            required
          />
          {isPhoneValid && <span className="check">✓</span>}
        </div>
        <small>{t('cellPhoneFormatHint')}</small>
      </div>
    </>
  );

  const isNextDisabled = !emailAvailability.isAvailable || !isPhoneValid || !passwordsMatch || !formData.email || !formData.password || !formData.confirmPassword || !formData.firstName || !formData.lastName || !formData.phoneNumber;

  const renderStep2 = () => (
    <>
      <h3>{t('tellUsAboutYourUse')}</h3>
      <div className="input-group">
        <div className="input-with-check">
          <select
            id="registrationType"
            name="registrationType"
            value={formData.registrationType}
            onChange={handleChange}
            required
            data-testid="registration-type-select"
          >
            <option value="">{t('selectAnOption')}</option>
            <option value="provider">{t('providerOption')}</option>
            <option value="company">{t('companyOption')}</option>
            <option value="retail">{t('retailOption')}</option>
          </select>
          {formData.registrationType && <span className="check">✓</span>}
        </div>
      </div>
      {formData.registrationType && (
        <>
          {formData.registrationType === 'provider' && (
            <div className="input-group">
              <label htmlFor="subtype">{t('selectProviderType')}</label>
              <div className="input-with-check">
                <select id="subtype" name="subtype" value={formData.subtype} onChange={handleChange} required>
                  <option value="">{t('selectProviderType')}</option>
                  <option value="distributor">{t('distributor')}</option>
                  <option value="manufacturer">{t('manufacturer')}</option>
                  <option value="service_provider">{t('serviceProvider')}</option>
                </select>
                {formData.subtype && <span className="check">✓</span>}
              </div>
            </div>
          )}
          {formData.registrationType === 'company' && (
            <div className="input-group">
              <label htmlFor="subtype">{t('selectCompanyType')}</label>
              <div className="input-with-check">
                <select id="subtype" name="subtype" value={formData.subtype} onChange={handleChange} required data-testid="company-type-select">
                  <option value="">{t('selectCompanyType')}</option>
                  <option value="construction">{t('constructionCompany')}</option>
                  <option value="contractor">{t('contractor')}</option>
                  <option value="architect">{t('architect')}</option>
                </select>
                {formData.subtype && <span className="check">✓</span>}
              </div>
            </div>
          )}
          {formData.registrationType !== 'retail' && (
            <div className="input-group">
              <label htmlFor="companyName">{t('companyName')}</label>
              <div className="input-with-check">
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder={t('companyName')}
                  required
                  data-testid="company-name-input"
                />
                {formData.companyName && <span className="check">✓</span>}
              </div>
            </div>
          )}
          <div className="input-group">
            <label htmlFor="additionalInfo">{t('additionalInfo')}</label>
            <textarea
              id="additionalInfo"
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
              placeholder={t('additionalInfo')}
            />
          </div>
        </>
      )}
    </>
  );

  return (
    <div className="signup-page">
      <div className="signup-container">
        {registrationStatus !== 'success' && (
          <>
            <button onClick={() => step > 1 ? setStep(step - 1) : navigate('/')} className="go-back-button">
              <span className="arrow-left">&#8592;</span> {t('goBack')}
            </button>
            {step === 1 && <h2>{t('signupTitle')}</h2>}
            <form onSubmit={handleSubmit}>
              {step === 1 && renderStep1()}
              {step === 2 && renderStep2()}
              <button 
                type="submit" 
                data-testid={step === 1 ? "next-button" : "complete-registration-button"}
                disabled={
                  (step === 1 && isNextDisabled) || 
                  (step === 2 && (!formData.registrationType || (formData.registrationType !== 'retail' && !formData.subtype) || (formData.registrationType !== 'retail' && !formData.companyName))) ||
                  isLoading
                }
              >
                {step === 1 ? t('next') : (isLoading ? t('processingRegistration') : t('completeRegistration'))}
              </button>
            </form>
          </>
        )}
        {registrationStatus && (
          <div className="registration-complete">
            <p className={`registration-status ${registrationStatus === 'success' ? 'success' : 'error'}`}>
              {registrationStatus === 'success' ? t('registrationComplete') : registrationStatus}
            </p>
            {registrationStatus === 'success' && (
              <a href="/login" className="complete-registration-link">{t('loginButton')}</a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUp;