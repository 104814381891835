import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../api';

const AccountActivation = () => {
  const { t } = useTranslation();
  const { uidb64, token } = useParams();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const activateAccount = async () => {
      try {
        const response = await api.get(`/users/activate/${uidb64}/${token}/`);
        setMessage(response.data.message);
      } catch (error) {
        setMessage(t('accountActivationFailed'));
      } finally {
        setIsLoading(false);
      }
    };

    activateAccount();
  }, [uidb64, token, t]);

  if (isLoading) {
    return <div>{t('activatingAccount')}</div>;
  }

  return (
    <div>
      <h1>{t('accountActivation')}</h1>
      <p>{message}</p>
      <Link to="/">
        <button>{t('goToHomePage')}</button>
      </Link>
    </div>
  );
};

export default AccountActivation;
