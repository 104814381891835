import React from 'react';
import { useTranslation } from 'react-i18next';

const Projects = () => {
  const { t } = useTranslation();

  return (
    <div className="projects">
      <h2>{t('projectsTitle')}</h2>
      <p>{t('projectsDescription')}</p>
      {/* Add project list or grid here */}
    </div>
  );
};

export default Projects;