import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import './UserProfile.css';

const UserProfile = () => {
  const { t, i18n } = useTranslation();
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const [passwordForm, setPasswordForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });
  const [passwordStrength, setPasswordStrength] = useState('');

  const fetchProfile = useCallback(async () => {
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await api.get('/users/profile/');
      setProfile(response.data);
      
      // Update the user's name in localStorage
      localStorage.setItem('userName', `${response.data.first_name} ${response.data.last_name}`);
    } catch (err) {
      setError(t('failedToFetchProfile'));
    }
  }, [navigate, t]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const handleEdit = () => {
    setIsEditing(true);
    setError('');
  };

  const handleSave = async () => {
    try {
      const updatedProfile = {
        first_name: profile.first_name,
        last_name: profile.last_name,
        phone_number: profile.phone_number,
        language: profile.language
      };

      if (profile.avatar && profile.avatar.startsWith('data:image')) {
        updatedProfile.avatar = profile.avatar;
      }

      await api.put('/users/profile/', updatedProfile);
      setIsEditing(false);
      setError('');

      // Update localStorage with the new first name
      localStorage.setItem('firstName', profile.first_name);

      // Dispatch a custom event to notify other components of the change
      window.dispatchEvent(new Event('profileUpdated'));

      // Update the app's language after successful profile update
      if (i18n.language !== profile.language) {
        try {
          await i18n.changeLanguage(profile.language);
        } catch (error) {
          console.error('Failed to change language:', error);
        }
      }
    } catch (err) {
      setError(t('failedToUpdateProfile'));
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setError('');
    fetchProfile();
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfile(prevProfile => ({
          ...prevProfile,
          avatar: reader.result
        }));
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      setProfile(prevProfile => ({
        ...prevProfile,
        [name]: value
      }));
    }
  };

  const getLanguageName = (langCode) => {
    switch (langCode) {
      case 'en':
        return t('english');
      case 'es':
        return t('spanish');
      default:
        return t('unknown');
    }
  };

  const checkPasswordStrength = (password) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    if (strongRegex.test(password)) {
      setPasswordStrength('strong');
    } else if (mediumRegex.test(password)) {
      setPasswordStrength('medium');
    } else {
      setPasswordStrength('weak');
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (passwordForm.newPassword !== passwordForm.confirmNewPassword) {
      setError(t('passwordsDontMatch'));
      return;
    }
    try {
      await api.post('/users/change-password/', {
        current_password: passwordForm.currentPassword,
        new_password: passwordForm.newPassword
      });
      setError('');
      setPasswordForm({ currentPassword: '', newPassword: '', confirmNewPassword: '' });
      setPasswordStrength('');
      alert(t('passwordChangeSuccess'));
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError(t('failedToChangePassword'));
      }
    }
  };

  if (!profile) {
    return <div>{t('loading')}</div>;
  }

  return (
    <div className="user-profile">
      <h1>{t('profileTitle')}</h1>
      {error && <div className="error-message">{error}</div>}
      {!isEditing && (
        <button onClick={handleEdit} className="edit-button">
          <i className="fas fa-edit"></i>
        </button>
      )}
      <div className="profile-details">
        <div className={`profile-avatar ${isEditing ? 'editing' : ''}`}>
          {profile.avatar ? (
            <img src={profile.avatar} alt={t('avatarAlt')} />
          ) : (
            <i className="fas fa-user"></i>
          )}
          {isEditing && (
            <input
              type="file"
              name="avatar"
              onChange={handleChange}
              accept="image/*"
            />
          )}
        </div>
        <div className="profile-info">
          <div className="info-row">
            <label>{t('firstNameLabel')}:</label>
            {isEditing ? (
              <input
                type="text"
                name="first_name"
                value={profile.first_name}
                onChange={handleChange}
              />
            ) : <span>{profile.first_name}</span>}
          </div>
          <div className="info-row">
            <label>{t('lastNameLabel')}:</label>
            {isEditing ? (
              <input
                type="text"
                name="last_name"
                value={profile.last_name}
                onChange={handleChange}
              />
            ) : <span>{profile.last_name}</span>}
          </div>
          <div className="info-row">
            <label>{t('phoneNumberLabel')}:</label>
            {isEditing ? (
              <input
                type="tel"
                name="phone_number"
                value={profile.phone_number || ''}
                onChange={handleChange}
              />
            ) : <span>{profile.phone_number || t('notProvided')}</span>}
          </div>
          <div className="info-row">
            <label>{t('languageLabel')}:</label>
            {isEditing ? (
              <select
                name="language"
                value={profile.language}
                onChange={handleChange}
              >
                <option value="en">{t('english')}</option>
                <option value="es">{t('spanish')}</option>
              </select>
            ) : <span>{getLanguageName(profile.language)}</span>}
          </div>
        </div>
      </div>
      {isEditing && (
        <div className="profile-actions">
          <button onClick={handleSave} className="save-button">{t('saveButton')}</button>
          <button onClick={handleCancel} className="cancel-button">{t('cancel')}</button>
        </div>
      )}
      {isEditing && (
        <div className="password-change-section">
          <h3>{t('changePassword')}</h3>
          <form onSubmit={handlePasswordChange}>
            <div className="info-row">
              <label>{t('currentPassword')}:</label>
              <input
                type="password"
                value={passwordForm.currentPassword}
                onChange={(e) => setPasswordForm({...passwordForm, currentPassword: e.target.value})}
                required
              />
            </div>
            <div className="info-row">
              <label>{t('newPassword')}:</label>
              <div className="password-input-wrapper">
                <input
                  type="password"
                  value={passwordForm.newPassword}
                  onChange={(e) => {
                    setPasswordForm({...passwordForm, newPassword: e.target.value});
                    checkPasswordStrength(e.target.value);
                  }}
                  required
                />
                {passwordForm.newPassword && (
                  <span className={`password-strength ${passwordStrength}`}>
                    {t(`passwordStrength${passwordStrength.charAt(0).toUpperCase() + passwordStrength.slice(1)}`)}
                  </span>
                )}
              </div>
            </div>
            <div className="info-row">
              <label>{t('confirmNewPassword')}:</label>
              <input
                type="password"
                value={passwordForm.confirmNewPassword}
                onChange={(e) => setPasswordForm({...passwordForm, confirmNewPassword: e.target.value})}
                required
              />
            </div>
            <button type="submit" className="change-password-button">
              <i className="fas fa-key"></i> {t('changePasswordButton')}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
