import React from 'react';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <div className="dashboard">
      <h2>{t('dashboardTitle')}</h2>
      <p>{t('dashboardDescription')}</p>
      {/* Add more dashboard content here */}
    </div>
  );
};

export default Dashboard;