import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';
import api from '../api';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = async (lng) => {
    try {
      await api.post(`${process.env.REACT_APP_API_URL}/users/set-user-language/`, { language: lng });
      i18n.changeLanguage(lng);
    } catch (error) {
      console.error('Error setting language:', error);
    }
  };

  return (
    <div className="language-switcher">
      <button onClick={() => changeLanguage('en')} className={i18n.language === 'en' ? 'active' : ''}>
        🇺🇸
      </button>
      <button onClick={() => changeLanguage('es')} className={i18n.language === 'es' ? 'active' : ''}>
        🇪🇸
      </button>
    </div>
  );
};

export default LanguageSwitcher;
