import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../api';
import './AcceptInvitation.css';

const AcceptInvitation = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const checkPasswordsMatch = useCallback(() => {
    setPasswordsMatch(formData.password === formData.confirmPassword);
  }, [formData.password, formData.confirmPassword]);

  useEffect(() => {
    checkPasswordStrength(formData.password);
    checkPasswordsMatch();
  }, [formData.password, formData.confirmPassword, checkPasswordsMatch]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const checkPasswordStrength = (password) => {
    const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
    const mediumRegex = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;

    if (strongRegex.test(password)) {
      setPasswordStrength('strong');
    } else if (mediumRegex.test(password)) {
      setPasswordStrength('medium');
    } else {
      setPasswordStrength('weak');
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!passwordsMatch) {
      setError(t('passwordsDontMatch'));
      return;
    }

    try {
      await api.post('/users/invites/accept_invitation/', {
        token,
        password: formData.password,
      });
      setSuccess(true);
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      setError(error.response?.data?.error || t('failedToAcceptInvitation'));
    }
  };

  return (
    <div className="accept-invitation-page">
      <div className="accept-invitation-container">
        <h2>{t('acceptInvitation')}</h2>
        {success ? (
          <p className="success">{t('invitationAcceptedSuccessfully')}</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <label htmlFor="password">{t('passwordLabel')}</label>
              <div className="input-with-check">
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder={t('enterPassword')}
                  required
                />
                {formData.password && <span className="check">✓</span>}
              </div>
              {formData.password && (
                <p className={`password-strength ${passwordStrength}`}>
                  {t(`passwordStrength${passwordStrength.charAt(0).toUpperCase() + passwordStrength.slice(1)}`)}
                </p>
              )}
            </div>
            <div className="input-group">
              <label htmlFor="confirmPassword">{t('confirmPasswordLabel')}</label>
              <div className="input-with-check">
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  placeholder={t('confirmPassword')}
                  required
                />
                {passwordsMatch && formData.confirmPassword && <span className="check">✓</span>}
              </div>
              {formData.confirmPassword && (
                <p className={`password-match ${passwordsMatch ? 'match' : 'no-match'}`}>
                  {passwordsMatch ? t('passwordsMatch') : t('passwordsDontMatch')}
                </p>
              )}
            </div>
            {error && <p className="error">{error}</p>}
            <button type="submit" className="accept-button" disabled={!passwordsMatch}>
              {t('acceptInvitation')}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default AcceptInvitation;