import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import GoogleLogin from './GoogleLogin';

const Login = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const errorMessage = params.get('error');
    if (errorMessage) {
      setError(decodeURIComponent(errorMessage));
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await api.post(`${process.env.REACT_APP_API_URL}/token/`, {
        email,
        password,
      });

      localStorage.setItem('accessToken', response.data.access);
      localStorage.setItem('refreshToken', response.data.refresh);
      navigate('/in');
    } catch (err) {
      setError(t('invalidEmailOrPassword'));
    }
  };

   return (
    <div className="login-page">
      <div className="login-container" data-testid="login-container">
        <button onClick={() => navigate('/')} className="go-back-button">
          <span className="arrow-left">&#8592;</span> {t('goBack')}
        </button>
        <img src="/Ukon-Logo.png" alt="Ukon Logo" className="login-logo" />
        <h2>{t('loginTitle')}</h2>
        <p className="login-subtitle">{t('subtitle')}</p>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="email">{t('emailLabel')}</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder={t('emailPlaceholder')}
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">{t('passwordLabel')}</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder={t('passwordPlaceholder')}
            />
          </div>
          <button type="submit" className="login-button" data-testid="login-button">{t('loginButton')}</button>
        </form>
        <div className="or-divider">
          <span>{t('or')}</span>
        </div>
        <GoogleLogin />
        <p className="signup-link">
          {t('noAccount')} <a href="/signup">{t('signUpLink')}</a>
        </p>
        <p className="forgot-password-link">
          <a href="/forgot-password">{t('forgotPassword')}</a>
        </p>
      </div>
    </div>
  );
};

export default Login;
