import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../api'; // Assuming you have an api utility for making requests

const CompanyInfoTab = () => {
  const { t } = useTranslation();
  const [companyName, setCompanyName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchStakeholderName = async () => {
      try {
        const response = await api.get('/stakeholders/mine/');
        setCompanyName(response.data.name); // Assuming the API returns stakeholder_name
      } catch (error) {
        console.error('Failed to fetch stakeholder name', error);
      }
    };

    fetchStakeholderName();
  }, []);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await api.patch('/stakeholders/mine/', { name: companyName });
      setIsSaving(false);
      alert(t('companyNameSavedSuccessfully'));
    } catch (error) {
      console.error('Failed to save company name', error);
      setIsSaving(false);
      alert(t('failedToSaveCompanyName'));
    }
  };

  return (
    <div className="tab-content-container">
      <div className="company-info-section">
        <h3>{t('companyInformation')}</h3>
        <div className="input-group">
          <label htmlFor="companyName">{t('companyName')}</label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder={t('enterCompanyName')}
          />
        </div>
        <button className="save-button" onClick={handleSave} disabled={isSaving}>
          {isSaving ? t('saving') : t('save')}
        </button>
      </div>
    </div>
  );
};

export default CompanyInfoTab;