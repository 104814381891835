import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../api';
import './UserManagement.css';
import { FaEdit, FaPlus, FaTimes, FaUserEdit, FaPaperPlane, FaUserMinus, FaUserTimes, FaUserCheck } from 'react-icons/fa';
import styled from 'styled-components';

const UserManagement = () => {
  const { t } = useTranslation();
  const [invitations, setInvitations] = useState([]);
  const [newInvitation, setNewInvitation] = useState({ email: '', role: '' });
  const [isAdding, setIsAdding] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [companyType, setCompanyType] = useState('');
  const [currentUserId, setCurrentUserId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState('email');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    fetchInvitations();
    fetchCompanyType();
    fetchCurrentUserId();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCompanyType = async () => {
    try {
      const response = await api.get('/stakeholders/mine/');
      setCompanyType(response.data.type);
    } catch (error) {
      console.error('Error fetching company type:', error);
    }
  };

  const fetchInvitations = async () => {
    try {
      const response = await api.get('/users/invites/');
      const invitationsWithType = response.data.map(invitation => ({
        ...invitation,
        companyType: companyType
      }));
      setInvitations(invitationsWithType);
    } catch (error) {
      console.error('Error fetching invitations:', error);
    }
  };

  const fetchCurrentUserId = async () => {
    try {
      const response = await api.get('/users/me/');
      setCurrentUserId(response.data.id);
    } catch (error) {
      console.error('Error fetching current user ID:', error);
    }
  };

  const handleAddInvitation = async () => {
    try {
      await api.post('/users/invites/', newInvitation);
      setNewInvitation({ email: '', role: '' });
      setIsAdding(false);
      fetchInvitations();
    } catch (error) {
      console.error('Error adding invitation:', error);
      if (error.response && error.response.data) {
        alert(t(error.response.data.error) || t('failedToAddInvitation'));
      } else {
        alert(t('failedToAddInvitation'));
      }
    }
  };


  const handleDeleteInvitation = async (id) => {
    try {
      await api.delete(`/users/invites/${id}/`);
      fetchInvitations();
    } catch (error) {
      console.error('Error deleting invitation:', error);
    }
  };

  const handleResendInvitation = async (id) => {
    try {
      await api.post(`/users/invites/${id}/resend_invitation/`);
      alert(t('invitationResent'));
    } catch (error) {
      console.error('Error resending invitation:', error);
    }
  };

  const handleToggleUserStatus = async (id) => {
    try {
      await api.post(`/users/${id}/toggle_status/`);
      fetchInvitations();
    } catch (error) {
      console.error('Error toggling user status:', error);
    }
  };

  const handleUpdateRole = async (id, newRole, isUser) => {
    try {
      if (isUser) {
        await api.patch(`/users/${id}/`, { role: newRole });
      } else {
        await api.patch(`/users/invites/${id}/`, { role: newRole });
      }
      setEditingId(null);
      fetchInvitations();
    } catch (error) {
      console.error('Error updating role:', error);
    }
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedInvitations = [...invitations].sort((a, b) => {
    let aValue, bValue;

    switch (sortColumn) {
      case 'fullName':
        aValue = a.accepted_user ? a.accepted_user.full_name : '';
        bValue = b.accepted_user ? b.accepted_user.full_name : '';
        break;
      case 'lastLogin':
        aValue = a.accepted_user && a.accepted_user.last_login ? new Date(a.accepted_user.last_login) : new Date(0);
        bValue = b.accepted_user && b.accepted_user.last_login ? new Date(b.accepted_user.last_login) : new Date(0);
        break;
      case 'status':
        aValue = a.is_accepted ? (a.accepted_user.is_active ? 'active' : 'inactive') : 'pending';
        bValue = b.is_accepted ? (b.accepted_user.is_active ? 'active' : 'inactive') : 'pending';
        break;
      default:
        aValue = a[sortColumn];
        bValue = b[sortColumn];
    }

    if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  const StatusToggleButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
    margin: 0 5px;
    border-radius: 4px;
    transition: background-color 0.3s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.activate {
      color: #4CAF50;
    }

    &.deactivate {
      color: #F44336;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;

  return (
    <div className="user-management-container">
      <div className="user-management-header">
        <h2>{t('userManagement')}</h2>
        {!isAdding && (
          <button
            className="invite-user-button"
            onClick={() => setIsAdding(true)}
          >
            <FaPlus className="inline-block mr-2" />
            {t('inviteUser')}
          </button>
        )}
      </div>
      <div className="search-container">
        <input
          type="text"
          placeholder={t('searchEmailOrName')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>
      {isAdding ? (
        <div className="invite-form">
          <button
            onClick={() => setIsAdding(false)}
            className="close-button"
          >
            <FaTimes />
          </button>
          <div className="invite-form-content">
            <label htmlFor="email">{t('email')}:</label>
            <input
              type="email"
              id="email"
              placeholder={t('email')}
              value={newInvitation.email}
              onChange={(e) => setNewInvitation({ ...newInvitation, email: e.target.value })}
            />
            <label htmlFor="role">{t('role')}:</label>
            <select
              id="role"
              value={newInvitation.role}
              onChange={(e) => setNewInvitation({ ...newInvitation, role: e.target.value })}
            >
              <option value="">{t('selectRole')}</option>
              {companyType === 'provider' && (
                <>
                  <option value="ADMIN">{t('admin')}</option>
                  <option value="STAFF">{t('staff')}</option>
                </>
              )}
              {companyType === 'company' && (
                <>
                  <option value="ADMIN">{t('admin')}</option>
                  <option value="BUYER">{t('buyer')}</option>
                </>
              )}
            </select>
            <button
              onClick={handleAddInvitation}
              className="send-invite-button"
            >
              {t('sendInvite')}
            </button>
          </div>
        </div>
      ) : (
        <table className="user-table">
          <thead>
            <tr className="bg-gray-200">
            {['email', 'fullName', 'role', 'status', 'lastLogin'].map((column) => (
                <th
                  key={column}
                  className="border px-4 py-2 cursor-pointer"
                  onClick={() => handleSort(column)}
                >
                  {t(column)}
                  {sortColumn === column && (
                    <span className="ml-1">
                      {sortDirection === 'asc' ? '▲' : '▼'}
                    </span>
                  )}
                </th>
              ))}
              <th className="border px-4 py-2">{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {sortedInvitations.filter(invitation => 
              invitation.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
              (invitation.accepted_user && 
               `${invitation.accepted_user.first_name} ${invitation.accepted_user.last_name}`
                 .toLowerCase()
                 .includes(searchTerm.toLowerCase()))
            ).map((invitation) => (
              <tr key={invitation.id}>
                <td className="border px-4 py-2">{invitation.email}</td>
                <td className="border px-4 py-2">
                  {invitation.accepted_user ? invitation.accepted_user.full_name : '-'}
                </td>
                <td className="border px-4 py-2">
                  {editingId === invitation.id ? (
                    <select
                      value={invitation.is_accepted ? invitation.accepted_user.role : invitation.role}
                      onChange={(e) => handleUpdateRole(
                        invitation.is_accepted ? invitation.accepted_user.id : invitation.id,
                        e.target.value,
                        invitation.is_accepted
                      )}
                      className="border rounded px-2 py-1"
                    >
                      {invitation.companyType === 'provider' ? (
                        <>
                          <option value="ADMIN">{t('admin')}</option>
                          <option value="STAFF">{t('staff')}</option>
                        </>
                      ) : (
                        <>
                          <option value="ADMIN">{t('admin')}</option>
                          <option value="BUYER">{t('buyer')}</option>
                        </>
                      )}
                    </select>
                  ) : (
                    <>
                      {t((invitation.is_accepted ? invitation.accepted_user.role : invitation.role).toLowerCase())}
                      {invitation.is_accepted && invitation.accepted_user.role !== 'OWNER' && (
                        <button
                          onClick={() => setEditingId(invitation.id)}
                          className="edit-role-button ml-2"
                          title={t('editRole')}
                        >
                          <FaEdit />
                        </button>
                      )}
                    </>
                  )}
                </td>
                <td className="border px-4 py-2">
                  {invitation.is_accepted
                    ? invitation.accepted_user.is_active
                      ? t('active')
                      : t('inactive')
                    : t('pending')}
                </td>
                <td className="border px-4 py-2">
                  {invitation.accepted_user && invitation.accepted_user.last_login
                    ? new Date(invitation.accepted_user.last_login).toLocaleString()
                    : '-'}
                </td>
                <td className="border px-4 py-2">
                  <div className="action-buttons">
                    {!invitation.is_accepted && (
                      <>
                        <button
                          onClick={() => setEditingId(invitation.id)}
                          className="invitation-edit-button"
                          title={t('editInvitation')}
                        >
                          <FaUserEdit />
                        </button>
                        <button
                          onClick={() => handleResendInvitation(invitation.id)}
                          className="resend-button"
                          title={t('resendInvitation')}
                        >
                          <FaPaperPlane />
                        </button>
                        <button
                          onClick={() => handleDeleteInvitation(invitation.id)}
                          className="delete-button"
                          title={t('deleteInvitation')}
                        >
                          <FaUserMinus />
                        </button>
                      </>
                    )}
                    {invitation.is_accepted && invitation.accepted_user && !invitation.accepted_user.is_owner && invitation.accepted_user.role !== 'OWNER' && (
                    <StatusToggleButton
                        onClick={() => handleToggleUserStatus(invitation.accepted_user.id)}
                        className={invitation.accepted_user.is_active ? 'deactivate' : 'activate'}
                        title={invitation.accepted_user.is_active ? t('deactivateUser') : t('activateUser')}
                        disabled={invitation.accepted_user.id === currentUserId}
                    >
                        {invitation.accepted_user.is_active ? <FaUserTimes /> : <FaUserCheck />}
                    </StatusToggleButton>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default UserManagement;