import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './SettingsTabs.css';
import CompanyInfoTab from './CompanyInfoTab';
import UserManagementTab from './UserManagementTab';
import BillingTab from './BillingTab';
import LocationsTab from './LocationsTab';

const SettingsTabs = ({ userType }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('info');

  const renderTabs = () => {
    const commonTabs = (
      <>
        <button className={activeTab === 'locations' ? 'active' : ''} onClick={() => setActiveTab('locations')}>
          {t('myLocations')}
        </button>
        <button className={activeTab === 'billing' ? 'active' : ''} onClick={() => setActiveTab('billing')}>
          {t('billing')}
        </button>
      </>
    );

    if (userType === 'Provider User' || userType === 'Company User') {
      return (
        <>
          <button className={activeTab === 'info' ? 'active' : ''} onClick={() => setActiveTab('info')}>
            {t('myCompanyInformation')}
          </button>
          <button className={activeTab === 'users' ? 'active' : ''} onClick={() => setActiveTab('users')}>
            {t('userManagement')}
          </button>
          {commonTabs}
        </>
      );
    } else if (userType === 'Retail User') {
      return commonTabs;
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'info':
        return <CompanyInfoTab />;
      case 'users':
        return <UserManagementTab />;
      case 'billing':
        return <BillingTab />;
      case 'locations':
        return <LocationsTab />;
      default:
        return null;
    }
  };

  return (
    <div className="settings-tabs">
      <div className="tabs">{renderTabs()}</div>
      <div className="tab-content">{renderContent()}</div>
    </div>
  );
};

export default SettingsTabs;