import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Home.css';
import LanguageSwitcher from './LanguageSwitcher';

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="home-container">
      <header className="home-header">
        <img src="/Ukon-Logo.png" alt="Ukon Logo" className="logo" />
        <nav>
          <LanguageSwitcher />
          <Link to="/login" className="login-button">{t('login')}</Link>
        </nav>
      </header>
      <main className="home-main">
        <h1>{t('mainTitle')}</h1>
        <p className="subtitle">{t('subtitle')}</p>
        <Link to="/signup" className="cta-button">{t('signUp')}</Link>
      </main>
      <section className="features-section">
        <div className="feature-container">
          <div className="feature">
            <h2>{t('feature1Title')}</h2>
            <p>{t('feature1Description')}</p>
          </div>
          <div className="feature">
            <h2>{t('feature2Title')}</h2>
            <p>{t('feature2Description')}</p>
          </div>
          <div className="feature">
            <h2>{t('feature3Title')}</h2>
            <p>{t('feature3Description')}</p>
          </div>
        </div>
      </section>
      <footer className="footer">
        {t('footer')}
      </footer>
    </div>
  );
};

export default Home;
