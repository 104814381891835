import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './locales/en.json';
import esTranslations from './locales/es.json';

const getInitialLanguage = async () => {
    return 'es'
};

const i18nInstance = i18n.createInstance();

i18nInstance
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      es: { translation: esTranslations },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export const initializeI18n = async () => {
  const language = await getInitialLanguage();
  await i18nInstance.changeLanguage(language);
  return i18nInstance;
};

export default i18nInstance;