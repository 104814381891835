import React from 'react';
import { useTranslation } from 'react-i18next';

const BillingTab = () => {
  const { t } = useTranslation();

  return (
    <div className="tab-content-container">
      {t('billingContent')}
    </div>
  );
};

export default BillingTab;