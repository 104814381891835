import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../api';
import { FaPlus, FaEdit, FaTrash, FaTimes } from 'react-icons/fa';

const LocationsManagement = () => {
  const { t } = useTranslation();
  const [locations, setLocations] = useState([]);
  const [newLocation, setNewLocation] = useState({
    name: '',
    location_type: 'OTHER',
    street_number: '',
    route: '',
    floor: '',
    apartment: '',
    city: '',
    state: '',
    country: '',
    postal_code: '',
    latitude: '',
    longitude: '',
    place_id: ''
  });
  const [editingLocation, setEditingLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const [locationTypes, setLocationTypes] = useState({});

  useEffect(() => {
    fetchLocations();
    fetchLocationTypes();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const fetchLocations = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/locations/');
      setLocations(response.data);
    } catch (error) {
      setError(t('failedToFetchLocations'));
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLocationTypes = async () => {
    try {
      const response = await api.get('/locations/types/');
      setLocationTypes(response.data);
    } catch (error) {
      console.error('Failed to fetch location types:', error);
    }
  };

  const handleInputChange = (e, isNewLocation = true) => {
    const { name, value } = e.target;
    if (isNewLocation) {
      setNewLocation({ ...newLocation, [name]: value });
    } else {
      setEditingLocation({ ...editingLocation, [name]: value });
    }
  };

  const handleAddLocation = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    try {
      await api.post('/locations/', newLocation);
      fetchLocations();
      setShowAddForm(false);
      setNewLocation({
        name: '',
        location_type: 'OTHER',
        street_number: '',
        route: '',
        floor: '',
        apartment: '',
        city: '',
        state: '',
        country: '',
        postal_code: '',
        latitude: '',
        longitude: '',
        place_id: ''
      });
    } catch (error) {
      setError(t('failedToAddLocation'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditLocation = (location) => {
    setEditingLocation(location);
    setError('');
  };

  const handleUpdateLocation = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    try {
      const response = await api.put(`/locations/${editingLocation.id}/`, editingLocation);
      setEditingLocation(null);
      setLocations(prevLocations => 
        prevLocations.map(loc => 
          loc.id === editingLocation.id ? response.data : loc
        )
      );
    } catch (error) {
      setError(t('failedToUpdateLocation'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteLocation = async (id) => {
    if (window.confirm(t('confirmDeleteLocation'))) {
      setIsLoading(true);
      try {
        await api.delete(`/locations/${id}/`);
        fetchLocations();
      } catch (error) {
        setError(t('failedToDeleteLocation'));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAddButtonClick = () => {
    setShowAddForm(!showAddForm);
    setError('');
  };

  if (isLoading) return <div>{t('loading')}</div>;

  return (
    <div className="locations-management">
      <div className="locations-header">
        <h3>{t('locationsManagement')}</h3>
        {!showAddForm && (
          <button 
            className="icon-button add-location-button" 
            onClick={() => setShowAddForm(true)} 
            aria-label={t('addLocation')}
            title={t('addLocation')}
          >
            <FaPlus />
          </button>
        )}
      </div>

      {showAddForm ? (
        <form onSubmit={handleAddLocation} className="location-form">
          <div className="form-header">
            <h4>{t('addLocation')}</h4>
            <button type="button" className="icon-button close-form-button" onClick={handleAddButtonClick}>
              <FaTimes />
            </button>
          </div>
          {error && <div className="error-message">{error}</div>}
          <div className="form-row">
            <label htmlFor="name">{t('locationName')}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={newLocation.name}
              onChange={(e) => handleInputChange(e)}
              required
            />
          </div>
          <div className="form-row">
            <label htmlFor="location_type">{t('locationType')}</label>
            <select
              id="location_type"
              name="location_type"
              value={newLocation.location_type}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="">{t('selectAnOption')}</option>
              {Object.entries(locationTypes).map(([key, value]) => (
                <option key={key} value={key}>{t(value.toLowerCase())}</option>
              ))}
            </select>
          </div>
          <hr className="form-separator" />
          <div className="form-row">
            <label htmlFor="route">{t('streetName')}</label>
            <input
              type="text"
              id="route"
              name="route"
              value={newLocation.route}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="form-row address-line">
            <label htmlFor="street_number">{t('streetNumber')}</label>
            <input
              type="text"
              id="street_number"
              name="street_number"
              value={newLocation.street_number}
              onChange={(e) => handleInputChange(e)}
            />
            <label htmlFor="floor">{t('floor')}</label>
            <input
              type="text"
              id="floor"
              name="floor"
              value={newLocation.floor}
              onChange={(e) => handleInputChange(e)}
            />
            <label htmlFor="apartment">{t('apartment')}</label>
            <input
              type="text"
              id="apartment"
              name="apartment"
              value={newLocation.apartment}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="form-row">
            <label htmlFor="city">{t('city')}</label>
            <input
              type="text"
              id="city"
              name="city"
              value={newLocation.city}
              onChange={(e) => handleInputChange(e)}
              required
            />
          </div>
          <div className="form-row">
            <label htmlFor="state">{t('state')}</label>
            <input
              type="text"
              id="state"
              name="state"
              value={newLocation.state}
              onChange={(e) => handleInputChange(e)}
              required
            />
          </div>
          <div className="form-row">
            <label htmlFor="country">{t('country')}</label>
            <input
              type="text"
              id="country"
              name="country"
              value={newLocation.country}
              onChange={(e) => handleInputChange(e)}
              required
            />
          </div>
          <div className="form-row">
            <label htmlFor="postal_code">{t('postalCode')}</label>
            <input
              type="text"
              id="postal_code"
              name="postal_code"
              value={newLocation.postal_code}
              onChange={(e) => handleInputChange(e)}
              required
            />
          </div>
          <button type="submit">{t('addLocation')}</button>
        </form>
      ) : (
        <>
          {locations.length === 0 ? (
            <p className="no-locations">{t('noLocations')}</p>
          ) : (
            <ul className="locations-list">
              {locations.map((location) => (
                <li key={location.id} className="location-item">
                  {editingLocation && editingLocation.id === location.id ? (
                    <form onSubmit={handleUpdateLocation} className="location-form">
                      <div className="form-header">
                        <h4>{t('editLocation')}</h4>
                        <button type="button" className="icon-button close-form-button" onClick={() => setEditingLocation(null)}>
                          <FaTimes />
                        </button>
                      </div>
                      {error && <div className="error-message">{error}</div>}
                      <div className="form-row">
                        <label htmlFor="edit-name">{t('locationName')}</label>
                        <input
                          type="text"
                          id="edit-name"
                          name="name"
                          value={editingLocation.name}
                          onChange={(e) => handleInputChange(e, false)}
                          required
                        />
                      </div>
                      <div className="form-row">
                        <label htmlFor="edit-location_type">{t('locationType')}</label>
                        <select
                          id="edit-location_type"
                          name="location_type"
                          value={editingLocation.location_type}
                          onChange={(e) => handleInputChange(e, false)}
                        >
                          <option value="">{t('selectAnOption')}</option>
                          {Object.entries(locationTypes).map(([key, value]) => (
                            <option key={key} value={key}>{t(value.toLowerCase())}</option>
                          ))}
                        </select>
                      </div>
                      <hr className="form-separator" />
                      <div className="form-row">
                        <label htmlFor="edit-route">{t('streetName')}</label>
                        <input
                          type="text"
                          id="edit-route"
                          name="route"
                          value={editingLocation.route}
                          onChange={(e) => handleInputChange(e, false)}
                        />
                      </div>
                      <div className="form-row address-line">
                        <label htmlFor="edit-street_number">{t('streetNumber')}</label>
                        <input
                          type="text"
                          id="edit-street_number"
                          name="street_number"
                          value={editingLocation.street_number}
                          onChange={(e) => handleInputChange(e, false)}
                        />
                        <label htmlFor="edit-floor">{t('floor')}</label>
                        <input
                          type="text"
                          id="edit-floor"
                          name="floor"
                          value={editingLocation.floor}
                          onChange={(e) => handleInputChange(e, false)}
                        />
                        <label htmlFor="edit-apartment">{t('apartment')}</label>
                        <input
                          type="text"
                          id="edit-apartment"
                          name="apartment"
                          value={editingLocation.apartment}
                          onChange={(e) => handleInputChange(e, false)}
                        />
                      </div>
                      <div className="form-row">
                        <label htmlFor="edit-city">{t('city')}</label>
                        <input
                          type="text"
                          id="edit-city"
                          name="city"
                          value={editingLocation.city}
                          onChange={(e) => handleInputChange(e, false)}
                          required
                        />
                      </div>
                      <div className="form-row">
                        <label htmlFor="edit-state">{t('state')}</label>
                        <input
                          type="text"
                          id="edit-state"
                          name="state"
                          value={editingLocation.state}
                          onChange={(e) => handleInputChange(e, false)}
                          required
                        />
                      </div>
                      <div className="form-row">
                        <label htmlFor="edit-country">{t('country')}</label>
                        <input
                          type="text"
                          id="edit-country"
                          name="country"
                          value={editingLocation.country}
                          onChange={(e) => handleInputChange(e, false)}
                          required
                        />
                      </div>
                      <div className="form-row">
                        <label htmlFor="edit-postal_code">{t('postalCode')}</label>
                        <input
                          type="text"
                          id="edit-postal_code"
                          name="postal_code"
                          value={editingLocation.postal_code}
                          onChange={(e) => handleInputChange(e, false)}
                          required
                        />
                      </div>
                      <button type="submit" className="save-button">{t('saveChanges')}</button>
                    </form>
                  ) : (
                    <>
                      <div className="location-info">
                        <h4>{location.name}</h4>
                        <p className="location-type">({t(location.location_type.toLowerCase())})</p>
                        <p className="location-address">{location.formatted_address || location.full_address}</p>
                      </div>
                      <div className="location-actions">
                        <button className="icon-button location-edit-button" onClick={() => handleEditLocation(location)} title={t('edit')}>
                          <FaEdit />
                        </button>
                        <button className="icon-button delete-button" onClick={() => handleDeleteLocation(location.id)} title={t('delete')}>
                          <FaTrash />
                        </button>
                      </div>
                    </>
                  )}
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

export default LocationsManagement;