import React, { useState, useEffect } from 'react';
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './UkonLayout.css';
import api from '../api';

const UkonLayout = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState(localStorage.getItem('firstName') || '');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        if (token) {
          const response = await api.get(`${process.env.REACT_APP_API_URL}/users/profile/`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setFirstName(response.data.first_name);
          localStorage.setItem('firstName', response.data.first_name);
        }
      } catch (err) {
        console.error('Failed to fetch profile data');
      }
    };

    fetchProfile();

    const handleProfileUpdate = () => {
      const updatedFirstName = localStorage.getItem('firstName');
      if (updatedFirstName) {
        setFirstName(updatedFirstName);
      }
    };

    window.addEventListener('profileUpdated', handleProfileUpdate);

    return () => {
      window.removeEventListener('profileUpdated', handleProfileUpdate);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate('/login');
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="ukonlayout-container">
      <aside className="sidebar">
        <div className="sidebar-header">
          <img src="/Ukon-Logo.png" alt="Ukon Logo" className="sidebar-logo" />
        </div>
        <nav className="sidebar-nav">
          <Link to="/in" className={`nav-item ${location.pathname === '/in' ? 'active' : ''}`} title={t('dashboard')}>
            <i className="fas fa-home"></i>
          </Link>
          <Link to="/in/projects" className={`nav-item ${location.pathname.startsWith('/in/projects') ? 'active' : ''}`} title={t('projects')}>
            <i className="fas fa-project-diagram"></i>
          </Link>
          <Link to="/in/analytics" className={`nav-item ${location.pathname.startsWith('/in/analytics') ? 'active' : ''}`} title={t('analytics')}>
            <i className="fas fa-chart-bar"></i>
          </Link>
          <Link to="/in/settings" className={`nav-item ${location.pathname.startsWith('/in/settings') ? 'active' : ''}`} title={t('settings')}>
            <i className="fas fa-cog"></i>
          </Link>
        </nav>
      </aside>
      <main className="main-content">
        <header className="main-header">
          <div className="user-menu">
            <span>{t('userGreeting', { name: firstName })}</span>
            <div className="avatar-dropdown">
              <i className="fas fa-user-circle user-avatar" onClick={toggleDropdown}></i>
              {isDropdownOpen && (
                <div className="dropdown-menu">
                  <Link to="/in/profile" className="dropdown-item">{t('profile')}</Link>
                  <button onClick={handleLogout} className="dropdown-item">{t('logout')}</button>
                </div>
              )}
            </div>
          </div>
        </header>
        <div className="content">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default UkonLayout;