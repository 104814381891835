import React from 'react';
import api from '../api';
import { useTranslation } from 'react-i18next';

const GoogleLogin = () => {
  const { t, i18n } = useTranslation();

  const handleGoogleLogin = async () => {
    try {
      const response = await api.post(`${process.env.REACT_APP_API_URL}/users/google/login/`, {
        callback_url: `${window.location.origin}/google-callback`,
        language: i18n.language
      });
      
      if (response.data && response.data.authorization_url) {
        window.location.href = response.data.authorization_url;
      } else {
        console.error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error initiating Google login:', error);
    }
  };

  return (
    <button onClick={handleGoogleLogin} className="google-login-button">
      <img src="/google-logo.svg" alt="Google logo" className="google-logo" />
      {t('signInWithGoogle')}
    </button>
  );
};

export default GoogleLogin;