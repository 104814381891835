import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsTabs from './SettingsTabs';
import api from '../api'; // Assuming you have an api utility for making requests

const Settings = () => {
  const { t } = useTranslation();
  const [userType, setUserType] = useState('');

  useEffect(() => {
    console.log('useEffect triggered'); // Log to check if useEffect is running

    const fetchUserType = async () => {
      try {
        console.log('Fetching user type...'); // Log before making the API call
        const response = await api.get('/users/profile/');
        console.log('API Response:', response.data); // Log the API response
        setUserType(response.data.user_type); // Assuming the API returns user_type
      } catch (error) {
        console.error('Failed to fetch user type', error);
      }
    };

    fetchUserType();
  }, []);

  return (
    <div className="settings">
      <h2>{t('settingsTitle')}</h2>
      <p>{t('settingsDescription')}</p>
      {userType && <SettingsTabs userType={userType} />}
    </div>
  );
};

export default Settings;